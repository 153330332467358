import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Auth, authState, getIdToken } from '@angular/fire/auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private auth: Auth) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (request.url.includes('ignoreAuth=true')) {
			return next.handle(request);
		}

		return authState(this.auth).pipe(
			switchMap((user) => user ? getIdToken(user) : EMPTY),
			switchMap((token) => {
				if (token) {
					request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
					return next.handle(request);
				}
				return EMPTY;
			})
		);
	}
}
